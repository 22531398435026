<template>
  <div class="container_box details_box_styles">
    <!--返回按钮-->
    <div class="go_back" style="width:100%;">
      <div class="back_styles" @click="goBackBtn">
        <Icon class="back_icon" type="ios-arrow-back"/>
        <span class="back_txt">{{ $t('key1001940') }}</span>
      </div>
      <div>
        <Button type="primary" @click="queryOrderBtn">{{ $t('key1000462') }}</Button>
      </div>
    </div>
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" class="basic_item" v-if="isWarehouseManagement === 'Y'">
            <span class="line_title">{{ $t('key1003563') }}</span>
            <Select v-model="outboundType" filterable transfer style="width:250px">
              <Option v-for="(item, index) in outboundTypeList" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" class="basic_item">
            <span class="line_title">{{ $t('key1005733') }}</span>
            <Select v-model="ymsWarehouseId" filterable>
              <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
            </Select>
          </Col>
        </Row>
        <div class="table_box">
          <div class="options_styles_btn">
            <Button type="primary" icon="md-add" @click="showAddProductModal">{{ $t('key1000857') }}</Button>
            <Button type="error" icon="md-trash" @click="deleteProductBtn" style="margin-left: 15px;">{{ $t('key1004172') }}</Button>
          </div>
          <Table
            highlight-row
            border
            max-height="560"
            :loading="tableLoading"
            :columns="packageGoodsColumns"
            @on-selection-change="getSelectValue"
            :data="packageGoodsList">
          </Table>
        </div>
      </Card>
    </div>
    <!--添加商品的弹窗-->
    <addCommonProductModal
      ref="addCommonProduct"
      modalWidth="1000"
      :showTree="false"
      :apiUrl="apiUrl"
      :showSelectSku="true"
      systemType="supplier"
      @updateGoodsData="updateData">
    </addCommonProductModal>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import addCommonProductModal from "@/components/common/addCommonProductModal.vue";
import Mixin from "@/components/mixin/common_mixin";
import api from "@/api";

export default {
  name: "initiateStocking",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      outboundTypeList: [
        {value: 2, name: alias47916751af154eb5b47cd5de9d34633d.t('key1001304')},
        {value: 3, name: alias47916751af154eb5b47cd5de9d34633d.t('key1001305')}
      ],
      outboundType: null,
      ymsWarehouseId: null,
      selectGoodsList: [],
      packageGoodsList: [],
      packageGoodsColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          type: 'index',
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000275'),
          width: 80,
          align: 'left'
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000161'),
          key: 'imagePath',
          minWidth: 120,
          align: 'left',
          render: (h, params) => {
            return this.tableImg(h, params, 'imagePath');
          }
        },
        {
          title: 'SKU',
          key: 'sku',
          align: 'left',
          minWidth: 180
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000193'),
          key: 'cnName',
          align: 'left',
          minWidth: 200
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000872'),
          key: 'availableNumber',
          align: 'left',
          minWidth: 100,
          render: (h, params) => {
            return h('span', v.priceTransform(params.row.availableNumber));
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000873'),
          key: 'totalSalesVolume',
          align: 'left',
          minWidth: 100,
          render: (h, params) => {
            return h('span', v.priceTransform(params.row.totalSalesVolume));
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1005799'),
          key: 'remark',
          align: 'left',
          minWidth: 160,
          render: (h, params) => {
            return h('Input', {
              props: {
                value: params.row.remark
              },
              style: {
                minWidth: '110px'
              },
              on: {
                input: (val) => {
                  v.$set(v.packageGoodsList[params.index], 'remark', val);
                }
              }
            });
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000446'),
          key: 'quantity',
          minWidth: 100,
          align: 'left',
          render: (h, params) => {
            return h('div', [
              h('InputNumber', {
                props: {
                  value: params.row.quantity ? params.row.quantity : null,
                  min: 0,
                  max: 9999999,
                },
                style: {
                  width: '100px',
                  marginRight: '5px'
                },
                on: {
                  'on-change': num => {
                    v.$set(v.packageGoodsList[params.index], 'quantity', num);
                  }
                }
              })
            ]);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000283'),
          key: 'action',
          align: 'left',
          fixed: 'right',
          width: 90,
          render: (h, params) => {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias47916751af154eb5b47cd5de9d34633d.t('key1000092'),
                    show: true,
                    clickFn: () => {
                      v.packageGoodsList.splice(params.index, 1);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      addProductModal: false,
      apiUrl: api.post_productGoods_query,
      warehouseList: []
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    // 是否开启仓储管理配置
    isWarehouseManagement() {
      return this.$store.state.isWarehouseManagement;
    },
  },
  created() {
    let v = this;
    v.getBelongingWarehouseData(v.userInfo.merchantId).then((data) => {
      if (data && data.length > 0) {
        v.warehouseList = data;
        let list = data.filter((item) => {
          return item.isFirst === 1
        });
        v.ymsWarehouseId = list.length > 0 ? list[0].ymsWarehouseId : null;
      }
    });
  },
  methods: {
    // 返回列表
    goBackBtn() {
      this.$router.push('/stockList');
    },
    // 获取已经选中的货品信息
    updateData(data) {
      let v = this;
      let list = JSON.parse(JSON.stringify(data));
      let new_arr = [...v.packageGoodsList, ...list];
      v.packageGoodsList = v.uniqueFunc(new_arr, 'productGoodsId');
    },
    // 勾选列表数据
    getSelectValue(value) {
      this.selectGoodsList = [];
      if (value) {
        value.map((item) => {
          this.selectGoodsList.push(item.productGoodsId);
        });
      }
    },
    // 添加商品
    showAddProductModal() {
      this.$refs['addCommonProduct'].initFun();
      this.$refs['addCommonProduct'].selectTableData = this.packageGoodsList;
    },
    // 移除商品
    deleteProductBtn() {
      let new_arr = [];
      if (this.selectGoodsList.length > 0) {
        this.packageGoodsList.map((item, index) => {
          if (!this.selectGoodsList.includes(item.productGoodsId)) {
            new_arr.push(item);
          }
        });
        this.packageGoodsList = new_arr;
      } else {
        this.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1004920'));
      }
      this.selectGoodsList = [];
    },
    // 保存
    queryOrderBtn() {
      let v = this;
      let query = {
        outboundType: null,
        ymsWarehouseId: null,
        packageGoodsList: []
      };
      if (v.isWarehouseManagement === 'Y') {
        if (v.outboundType) {
          query.outboundType = v.outboundType;
        } else {
          v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1005738'));
          return false;
        }
      }
      // 当供应商关闭仓储管理后，不再展示出库单类型选项，默认为自有仓备货。
      else {
        query.outboundType = 2;
      }
      if (v.ymsWarehouseId) {
        query.ymsWarehouseId = v.ymsWarehouseId;
      } else {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1005739'));
        return false;
      }
      if (v.packageGoodsList.length > 0) {
        for (let i = 0; i < v.packageGoodsList.length; i++) {
          let item = v.packageGoodsList[i];
          if (item.quantity === null || item.quantity === 0) {
            v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1005800'));
            return false;
          } else {
            let reg = v.$regular.positiveInteger;
            if (!reg.test(Number(item.quantity))) {
              v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1005801'));
              return false;
            }
            query.packageGoodsList.push({
              productGoodsId: item.productGoodsId,
              quantity: item.quantity,
              remark: item.remark
            });
          }
        }
        v.tableLoading = true;
        v.axios.post(api.post_packageInfo_createStock, query, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(response => {
          v.tableLoading = false;
          if (response.data.code === 0) {
            v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000085'));
            v.goBackBtn();
          }
        });
      } else {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1005802'));
      }
    }
  },
  components: {
    addCommonProductModal
  }
}
</script>

<style lang="less" scoped>
.details_box_styles {
  .basic_information_box {
    .options_styles_btn {
      margin: 15px 0;
    }
  }

  .go_back {
    display: flex;
    justify-content: space-between;
    cursor: initial;

    .back_styles {
      display: flex;
      cursor: pointer;
    }
  }
}

</style>